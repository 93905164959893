<template>
  <CtaTeaserSection
    :id="item.fields.id"
    :color-mode="item.fields.theme"
    :background-type="backgroundType"
    :button-links="links"
    :background-media="item.fields.backgroundMedia"
    :texts="splittedContent"
    :headlines="headlines"
    :image="image"
    :align-ctas-with-image="ctaAlignment"
  >
  </CtaTeaserSection>
</template>

<script setup lang="ts">
import { CtaTeaserSection } from '@hypercodestudio/basler-components';
import type { BgType } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/CtaTeaserSection.vue';
import type { ICtaSection } from '~/lib/ContentfulService';
import { mapHeadline } from '~/utils/mapHeadline';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';

interface Props {
  item: ICtaSection;
}

const props = defineProps<Props>();

const backgroundType = computed((): BgType => {
  switch (props.item.fields?.backgroundType) {
    case 'Image':
      return 'image';
    case 'Animation':
      return 'animation';
    default:
      return 'color';
  }
});

const headlines = computed(() =>
  (props.item.fields.advancedHeadlines ?? [])
    .filter((entry) => !isEntryResolveError(entry))
    .map((headline) => mapHeadline(headline))
);

const image = computed(() =>
  generateImageObject(props.item.fields.image, {
    widths: [212],
    keepAspectRatio: true,
    fit: 'pad'
  })
);

const links = computed(() =>
  (props.item.fields.links ?? [])
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((entry) => ({
      text: entry.fields.linkText,
      link: {
        link: useBuildLinkInterface(entry),
        size: entry.fields.linkStyleSize,
        styleType: entry.fields.linkStyle ?? 'default',
        iconName: entry.fields.linkIcon,
        targetBlank: entry.fields.linkTarget === '_blank',
        iconType: getLinkIconPosition(entry.fields.linkIconPosition)
      }
    }))
);

const ctaAlignment = computed(() => {
  return props.item.fields.image && !props.item.fields.alignCtasLeft
    ? true
    : props.item.fields.alignCtasLeft
    ? props.item.fields.alignCtasLeft
    : false;
});

const splittedContent = computed(
  () => props.item.fields.content?.split('\n') ?? []
);
</script>
